body{
  margin: 0;
}

*{
  font-family: 'Quicksand', sans-serif;
}

h1,p{
  margin-top: 8px;
  margin-bottom: 8px;
}

.MuiChip-colorPrimary{
  background-color: #587af7 !important;
}

a{
  text-decoration: none !important;
  color: inherit !important;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  border: none;
  background: none;
}

button:focus {outline:0;}

.cardContent::-webkit-scrollbar{
  width: 8px;
}

.cardContent::-webkit-scrollbar-thumb {
  background: rgb(65, 74, 95); 
}

tbody.MuiTableBody-root > tr:hover {
  opacity: 1 !important;
}

.MuiTableCell-head{
  padding: 4px !important;
  text-align: center;
}

#sideNav::-webkit-scrollbar{
  width: 8px;
}

#sideNav::-webkit-scrollbar-thumb {
  background: #acacb1; 
}

.PlayerRSWP{
  position: fixed;
  bottom: 0px;
  z-index: 999;
}